.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #94ca95;
  padding: 10px; /* Add padding to prevent content from touching the edges */
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
  padding-top: 70px;
}

.App-header {
  background-color: #94ca95;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #003D59;
}

.App-title {
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #003D59;
}

.App-link, .secondary {
  color: #c3f2ff;
}

.instructions {
  display: flex;
  flex-direction: column; /* Stack vertically on narrow screens */
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

@media (min-width: 800px) {
  .instructions {
    flex-direction: row; /* Stack horizontally on wider screens */
  }
}

.instruction-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  height: 200px;
  margin: 10px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border-radius: 50%;
  background-color: #00C7FF;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
}

.text h2 {
  margin: 10px 0;
  font-size: 18px;
}

.text p {
  margin: 0;
  font-size: 14px;
  text-align: center;
}

.tvl {
  margin: 20px 0;
  text-align: center;
}

.tvl h2 {
  font-size: medium;
  margin-bottom: 10px;
}

.tvl p {
  font-size: 18px;
  color: #003D59;
}

/* Footnote container */
.footnote-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
}

.footnote-container small.secondary {
  display: block;
  margin: 10px 0;
}

.footnote-container p.secondary {
  margin: 10px 0;
}

/* Responsive adjustments */
@media (max-width: 800px) {
  .App-header {
    font-size: calc(8px + 2vmin); /* Adjust font size on smaller screens */
  }

  .footnote-container {
    max-width: 300px;
  }

  .instruction-box {
    width: 100%; /* Full width on small screens */
    max-width: 300px; /* Limit max width */
    height: auto; /* Auto height */
    padding: 10px; /* Reduce padding */
  }

  .icon {
    width: 40px;
    height: 40px;
    font-size: 18px;
  }

  .text h2 {
    font-size: 16px; /* Smaller font size */
  }

  .text p {
    font-size: 12px; /* Smaller font size */
  }

  .tvl p {
    font-size: 16px;
  }
}

/* Colors
Dark Blue: #003D59
Orange: #FF9500
Yellow: #FFCC00
Light Blue: #00C7FF
White: #FFFFFF
*/